<script setup lang="ts">
const title = computed(() => {
  const trevoTitle = 'Como a Saúde Trevo funciona?'
  const labTitle = 'Como funciona?'

  return isTrevo() ? trevoTitle : labTitle
})

const cards = computed(() => {
  const multiLabCards = [
    { description: 'Busque todos os exames que você precisa na Saúde Trevo' },
    { description: 'Compare os valores e escolha o melhor laboratório para você' },
    { description: 'Agende seu procedimento e realize o pagamento com desconto' },
    { description: 'Realize o procedimento no laboratório parceiro escolhido' },
  ]

  const labCards = [
    { description: 'Busque todos os exames que você precisa no nosso laboratório' },
    { description: 'Agende seu procedimento e realize o pagamento com desconto' },
    { description: 'Realize seu atendimento na unidade escolhida e aguarde o resultado online' },
  ]

  return isMultiLab() ? multiLabCards : labCards
})
</script>

<template>
  <section class="relative container mx-auto flex justify-between flex-col md:flex-row gap-6 mb-14 mt-6 px-5">
    <span v-if="isTrevo()" class="hidden xl:flex gap-4 absolute top-[10%] right-[40%] w-[80%] z-0">
      <Img
        src="https://web-cdn.saudetrevo.com.br/illustrations/trevo_como_funciona_home.svg"
        alt="Ilustração de trevo."
        width="397"
        height="397"
      />
      <Img
        src="https://web-cdn.saudetrevo.com.br/illustrations/trevo_como_funciona_home.svg"
        alt="Segunda ilustração de trevo."
        width="397"
        height="397"
      />
    </span>
    <div class="flex flex-col gap-2 lg:max-w-[40%]">
      <div class="pt-6 sticky top-16">
        <h3 class="relative text-center md:text-left text-neutral-300 text-2xl md:text-5xl mb-2 font-sora font-semibold">
          {{ title }}
        </h3>
        <p class="relative text-center md:text-left text-neutral-300 text-xl">
          Veja como é fácil realizar seus exames conosco!
        </p>
      </div>
    </div>
    <div id="cards" class="lg:max-w-[40%]">
      <div
        v-for="(card, i) in cards"
        :id="`card${i + 1}`"
        :key="i"
        class="card"
      >
        <div
          class="card-body bg-bg-50 rounded-lg p-6 border border-bg-200 flex flex-col justify-between
            md:h-[200px] gap-4 md:gap-0"
        >
          <h4 class="text-secondary-500 text-5xl font-sora">
            {{ `0${i + 1}` }}
          </h4>
          <p class="text-neutral-300 xl:text-lg">
            {{ card.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
#cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 200px);
  padding-bottom: calc(4 * 1.5rem);
  margin-bottom: 1rem;
}

/** Mobile */
#card1 {
  position: sticky;
  top: 66px;
  padding-top: 0px;
}

#card2 {
  position: sticky;
  top: 66px;
  padding-top: 16px;
}

#card3 {
  position: sticky;
  top: 66px;
  padding-top: 32px;
}

#card4 {
  position: sticky;
  top: 0px;
  padding-top: 48px;
}

/** Large (lg) */
@media(min-width: theme('screens.md')) {
  #card1 {
    position: sticky;
    top: 90px;
    padding-top: 0;
  }

  #card2 {
    position: sticky;
    top: 90px;
    padding-top: 24px;
  }

  #card3 {
    position: sticky;
    top: 90px;
    padding-top: 48px;
  }

  #card4 {
    position: sticky;
    top: 0px;
    padding-top: 72px;
  }
}

.card-body {
  height: 200px;
  transition: all 0.5s;
}
</style>
